// import axios from "axios";
import xstore from "../components/xstore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { ethers } from "ethers";
const features = [
  {
    title: "Traders",
    image: "traders.png",
    desc: "Exchange tokens with minimal price deviation and incur some of the lowest transaction fees, which benefit DAIK lockers.",
  },
  {
    title: "Liquidity Providers",
    image: "liquidity.png",
    desc: "Swap tokens with minimal slippage and pay some of the lowest fees to DAIK lockers.",
  },
  {
    title: "Protocols",
    image: "protocols.png",
    desc: "Swap tokens with minimal slippage and pay some of the lowest fees to DAIK lockers.",
  },
  {
    title: "veDAIK Voters",
    image: "voters.png",
    desc: "Swap tokens with minimal slippage and pay some of the lowest fees to DAIK lockers.",
  },
]
const Landing = () => {
  // useEffect(() => {
  //   const urlSearchParams = new URLSearchParams(window.location.search);
  //   const params = Object.fromEntries(urlSearchParams.entries());
  //   // console.error("params -> ", params);
  //   if (params.ref || params.reference) {
  //     let addr = params.ref || params.reference;
  //     console.error("ref addr -> ", addr);
  //     try {
  //       addr = ethers.utils.getAddress(addr);
  //       setCookie("refAddress", addr, 365);
  //     } catch (e) {
  //       console.error("ethers.utils.getAddress(addr) -> ", e);
  //     }
  //   }
  // }, []);

  const setCookie = (cname: any, cvalue: any, exdays: any) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  return (
    <>
      <div className="bg-bg lg:pb-[140px] pb-[60px]">
        <div className="bg-main bg-contain bg-no-repeat bg-right-top relative z-[1] pb-[100px] md:pb-0">
          <header className="header font-fontHeading relative">
            <div className="container">
              <div className="flex flex-wrap items-center justify-center sm:justify-end border-b border-border py-3 lg:py-4">
                <div className="mr-auto">
                  <Link to="/">
                    <img
                      className="mx-auto sm:mx-0 h-[42px] sm:w-auto"
                      src={xstore.page.header.logo.imageUrl}
                      alt={xstore.page.header.logo.title}
                    />
                  </Link>
                </div>
                <div className="flex items-center">
                  {/* {xstore?.page.header.menuHome.map((item: any, index: any) => {
                  return (
                    <a
                      key={index}
                      className="lg:px-8 sm:px-4 px-2.5 leading-[24px] text-[14px] sm:text-[18px] xl:text-[20px]"
                      href={item.url}
                    >
                      {item.title}
                    </a>
                  );
                })} */}
                  <button className="bg-[#1F1A1C] border-[#1F1A1C] box-shadow text-primary text-[14px] font-medium">Connect Wallet</button>
                </div>
              </div>

            </div>
          </header>
          <div className="container xl:pt-[148px] pt-[70px] relative z-[1]">
            <div className="md:flex items-center">
              <div className="flex-1 bg-bgLeft bg-contain bg-no-repeat">
                <h1 className="mb-6">
                  Swap token and Inscription on <span className="text-primary">TAIKO</span>
                </h1>
                <div className="sm:flex items-center">
                  <Link
                    className="btn btn-white px-8"
                    to="#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="px-3">Explore</span>
                    <img className="" src="images/icon/arrow-right.svg" alt="" />
                  </Link>
                </div>
              </div>
              <div className="flex-1">
                <img className="mx-auto" src="images/home/bg-logo.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="container md:flex items-center py-[70px] lg:py-[135px]">
            <div className="flex-[0_0_60%] md:pr-5 mb-10 md:mb-0 relative bg-radial bg-car bg-no-repeat bg-bottom bg-contain">
              <img className="relative" src="images/home/crafted.png" alt="" />
            </div>
            <div className="flex-1 relative">
              <div className="md:max-w-[480px]">
                <h2 className="xl:text-[37px] md:text-[26px] font-medium tracking-[0.37px] mb-[48px]">Crafted to incentivize contributors who facilitate the protocol's enduring expansion.</h2>
                <Link
                  className="btn btn-white px-8"
                  to="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="px-3">Explore</span>
                  <img className="" src="images/icon/arrow-right.svg" alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="container md:flex flex-wrap">
            {features.map((item, index) => {
              return (
                <div key={index} className="flex-[0_0_50%] lg:flex-[0_0_25%] md:px-4 text-center mb-8">
                  <div className="px-6">
                    <img className="mx-auto mb-5 w-[100px] sm:w-auto" src={`images/home/${item.image}`} alt="" />
                    <h3 className="mb-4">{item.title}</h3>
                    <p className="text-[14px] text-colorOpacity">{item.desc}</p>
                  </div>
                </div>
              )
            })}
          </div>
          <footer className="pt-[100px]">
            <div className="container">
              <div className="flex">
                <div className="mr-auto">
                  <img src="images/home/top.png" alt="" />
                </div>
                <div>
                  <img src="images/home/line.png" alt="" />
                </div>
                <div className="ml-auto">
                  <img src="images/home/right.png" alt="" />
                </div>
              </div>
              <div className="lg:py-10 py-6">
                <img className="mx-auto h-[50px] md:h-auto mb-7" src="images/logo.svg" alt="" />
                <div className="flex justify-center">
                  {xstore?.page.header.socialLinks.map(
                    (item: any, index: any) => {
                      return (
                        <a
                          key={index}
                          className="px-2 md:px-4"
                          href={item.url}
                          target="_blank" rel="noreferrer"
                        >
                          <img
                            className=""
                            src={`/images/icon/${item.imageUrl}`}
                            alt=""
                          />
                        </a>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="flex items-end">
                <div className="mr-auto">
                  <img src="images/home/bottom.png" alt="" />
                </div>
                <div>
                  <img src="images/home/line.png" alt="" />
                </div>
                <div className="ml-auto">
                  <img src="images/home/left.png" alt="" />
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Landing;
